// @flow

import type { Node } from 'react'
import React from 'react'
import SeoPageTemplate from '../scenes/seo-pages'

function SeoFloridaCottages(): Node {
  return <SeoPageTemplate title="Florida Cottages" />
}

export default SeoFloridaCottages
